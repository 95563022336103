import "./home.css";

import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import GalleryBlock from "../sections/GalleryBlock";
import Services from "../sections/Services";
import Booking from "../sections/Booking";
import Blocks from "../sections/Blocks";
import { PAGE_ROUTES } from "../../utils/stateTypes";


const styles = {
  blockButton: {
    width: 200,
  },
  bookingInput: {
    marginBottom: 20,
  },
};

const Home = () => {
  return (
    <main>
      <Helmet>
        <title>TLC Nail Bar</title>
        <meta
          name={"description"}
          content={"TLC Nail Bar is a nail salon in the heart of Bedfordview, Johannesburg. We offer a range of nail treatments, including acrylic, gel, and nail art. Book your appointment today!"}
        ></meta>
      </Helmet>

      <header className={"home-header"}>
        <div className={"home-header-bg"} />
        <div className={"standard-width"}>
          <h1>
            Treat Yourself
            <br />
            With Tender, Loving Care
          </h1>
          <img
            className={"header-img"}
            src={"/images/home-header.jpg"}
          />
        </div>
      </header>

      <section className={"standard-width features-section"}>
        <div className={"feature"}>
          <img src={"/images/feature-1.png"} />
          <h2>Polish</h2>
          <p>
            Our nail technicians are experts in their craft,
            and with our high-quality products your nails
            will look and feel amazing.
          </p>
        </div>
        <div className={"feature"}>
          <img src={"/images/feature-2.png"} />
          <h2>Gelish</h2>
          <p>
            Our gelish treatments are the perfect solution for
            those who want a long-lasting, chip-free manicure.
          </p>
        </div>
        <div className={"feature"}>
          <img src={"/images/feature-3.png"} />
          <h2>Acrylic</h2>
          <p>
            We offer acrylic treatments for extra length and strength,
            with perfect results every time.
          </p>
        </div>
        <div className={"feature"}>
          <img src={"/images/feature-4.png"} />
          <h2>Relax</h2>
          <p>
            Add a spa treatment to your visit and leave feeling
            relaxed and rejuvenated.
          </p>
        </div>
      </section>

      <Blocks
        heading1={"A Heavenly Experience"}
        content1={
          <React.Fragment>
            <p>
              TLC Nail Bar in Bedfordview is a place where you can relax, unwind, and
              get pampered. Our team of experienced nail technicians
              will make sure you leave feeling fabulous.
            </p>
            <ul>
              <li>We offer a range of treatments and only use the highest quality products.</li>
              <li>All our staff members are expertly trained and have years of experience.</li>
            </ul>
          </React.Fragment>
        }
        image1={"/images/home-block-1.jpg"}
        heading2={"You Deserve It"}
        content2={
          <React.Fragment>
            <p>
              Upcoming event? Or just in need of some self-care? Whatever your reason,
              treat yourself to a visit to TLC Nail Bar. We know you deserve it.
            </p>
            <p>
              Book your appointment today - your nails will thank you!
            </p>
            <Link to={PAGE_ROUTES.CONTACT}>
              <Button
                variant={"contained"}
                color={"primary"}
                style={styles.blockButton}
              >
                Make an appointment
              </Button>
            </Link>
          </React.Fragment>
        }
        image2={"/images/home-block-2.jpg"}
      />

      <section className={"home-banner-section"}>
        <div className={"standard-width home-banner"}>
          <h2>For Nails That Tell a Story</h2>
        </div>
        <img
          className={"home-banner-img"}
          src={"/images/home-banner.jpg"}
        />
      </section>

      <Services />

      <section className={"standard-width gallery-section"}>
        <GalleryBlock
          image1={"/images/gal-1.jpg"}
          image2={"/images/gal-4.jpg"}
          image3={"/images/gal-5.jpg"}
          isReversed={true}
        />
        <GalleryBlock
          image1={"/images/gal-2.jpg"}
          image2={"/images/gal-3.jpg"}
          image3={"/images/gal-6.jpg"}
          isRight={true}
        />
      </section>

      <img
        className={"divider"}
        src={"/images/divider.png"}
      />

      <Booking />
    </main>
  );
};

export default Home;

