import "./booking.css";

import React from "react";
import { Button, TextField, CircularProgress } from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";

import { LOADING_STATE } from "../../utils/stateTypes";


const styles = {
  blockButton: {
    width: 200,
  },
  bookingInput: {
    marginBottom: 20,
  },
};

const sendMessage = async (name, email, date, phone, setSendState) => {
  setSendState(LOADING_STATE.LOADING);
  const response = await fetch("/api/Contact/SendContactMessage", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
      email,
      date,
      type: "Making an appointment",
      phone,
    }),
  });
  if (response.ok) {
    setSendState(LOADING_STATE.LOADED);
  }
  else {
    setSendState(LOADING_STATE.ERROR);
  }
};

const Booking = () => {

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [date, setDate] = React.useState(null);
  const [sendState, setSendState] = React.useState(LOADING_STATE.NOT_LOADING);

  let submitLabel = "Book Now";
  if (sendState === LOADING_STATE.LOADING) {
    submitLabel = <CircularProgress size={25} />;
  }
  else if (sendState === LOADING_STATE.LOADED) {
    submitLabel = "Sent!";
  }
  else if (sendState === LOADING_STATE.ERROR) {
    submitLabel = "Retry";
  }

  return (
    <section className={"standard-width standard-box booking-section"}>
      <img
        className={"booking-img"}
        src={"/images/booking-banner-1.jpg"}
      />
      <div className={"booking-content"}>
        <h2>Book Your Appointment</h2>
        <TextField
          label={"Name"}
          variant={"outlined"}
          style={styles.bookingInput}
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label={"Email"}
          variant={"outlined"}
          style={styles.bookingInput}
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label={"Phone"}
          variant={"outlined"}
          style={styles.bookingInput}
          fullWidth
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <DateTimePicker
          label={"Date & Time"}
          inputVariant={"outlined"}
          variant={"dialog"}
          DialogProps={{
            disableScrollLock: true,
          }}
          style={styles.bookingInput}
          fullWidth
          value={date}
          onChange={(e) => setDate(e)}
        />
        <Button
          variant={"contained"}
          color={"primary"}
          style={styles.blockButton}
          disabled={(sendState === LOADING_STATE.LOADING || sendState === LOADING_STATE.LOADED)}
          onClick={() => {
            sendMessage(name, email, date, phone, setSendState);
          }}
        >
          {submitLabel}
        </Button>
      </div>
    </section>
  );
};

export default Booking;

